






































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Loading: () => import('./Loading.vue'),
  },
})
export default class Editor extends Vue {
  @Prop({ type: String }) readonly uid!: string
  @Prop({ type: String }) readonly initialText?: string
  @Prop({ type: Boolean }) readonly isLoading?: boolean
  @Prop({ type: Boolean }) readonly canSubmitEmpty?: boolean

  @Ref() readonly input!: HTMLInputElement

  text = ''
  private preventBlur = false

  beforeMount (): void {
    if (this.initialText) this.text = this.initialText
  }

  mounted (): void {
    this.input.focus()
  }

  beforeDestroy (): void {
    if (this.text.trim() === (this.initialText ?? '').trim()) return
    this.$emit('unsaved', this.text)
  }

  get num (): number {
    return [23, 42][Math.floor(Math.random() * 2)]
  }

  get canSubmit (): boolean {
    return this.canSubmitEmpty || this.text.trim() !== ''
  }

  async submit (): Promise<void> {
    if (!this.canSubmit) return
    this.$emit('submit', this.text.trim(), () => (this.text = ''))
    this.input.focus()
  }

  close (): void {
    this.$emit('close')
  }

  onEnter (event: KeyboardEvent): void {
    if (event.ctrlKey || event.metaKey || event.shiftKey) return
    event.preventDefault()
    this.submit()
  }

  onClick (event: Event): void {
    if (event.target !== this.input) this.preventBlur = true
  }

  onInputBlur (): void {
    if (this.preventBlur) {
      this.preventBlur = false
      return
    }
    this.close()
  }
}
